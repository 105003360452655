
import { defineComponent } from "vue";

export default defineComponent({
  name: "HistoryRowLoader",
  props: {
    displaySettings: {
      type: Object,
      default: () => ({}),
    },
  },
});
